import Clipboard from "clipboard";

function hideTooltip(btn) {
  setTimeout(function hide() {
    $(btn).tooltip("hide");
  }, 500);
}

const initializeClipboardCopy = (selecter = ".js-copy-btn") => {
  const clipboard = new Clipboard(selecter);

  clipboard.on("success", function onCopySuccess(e) {
    const oldContent = e.trigger.innerHTML;
    e.trigger.innerHTML = "✓";
    setTimeout(() => {
      e.trigger.innerHTML = oldContent;
    }, 500);
    hideTooltip(e.trigger);
  });
};

export default initializeClipboardCopy;
