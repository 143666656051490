const initializePasswordValidation = () => {
  const passwordField = document.querySelector(".js-password-field");
  const passwordConfirmField = document.querySelector(
    ".js-password-confirm-field"
  );
  Array.prototype.filter.call(
    [passwordField, passwordConfirmField],
    function addListner(field) {
      if (field) {
        field.addEventListener("keyup", function onPasswordChange() {
          if (passwordField.value === passwordConfirmField.value) {
            passwordConfirmField.setCustomValidity("");
          } else {
            passwordConfirmField.setCustomValidity("Passwort nicht abgestimmt");
          }
        });
      }
    }
  );
};

export default initializePasswordValidation;
