import { Controller } from "@hotwired/stimulus";
import 'cropperjs/dist/cropper.css';
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ["source", "output"];
  connect() {
      this.createCropper();
  }

  createCropper() {
    this.cropper = new Cropper(this.sourceTarget);
  }

  save = (e) => {
    e.preventDefault();

    let response = confirm("Do you want to save current changes?");
    if (response) {
      let final_result = this.cropper.getCroppedCanvas({ 
        imageSmoothingQuality: 'high',
        fillColor: '#fff'
      }).toDataURL(JSON.parse(this.data.get("filetype"))[1])
      this.postToAPI(final_result);
    }
  };

  r_left = (e) => {
    e.preventDefault();
    this.cropper.rotate(-45);
  };

  r_right = (e) => {
    e.preventDefault();
    this.cropper.rotate(45);
  };

  clear_crop = (e) => {
    e.preventDefault();
    this.cropper.clear();
  };

  postToAPI(outputData) {
    const photo_id = this.data.get("id");
    const file_type = JSON.parse(this.data.get("filetype"))[0];
    const dataURL = outputData;
    fetch(`/save_image_adjustments/${photo_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        file_type: file_type,
        output_image: dataURL
      }),
    }).then(response => {
      if (response.ok) {
        this.outputTarget.src = dataURL
        alert("Image Updated Successfully!");
      }
      else{
        alert("Image Update Failed!");
      }
    });
  }
}