const initializeDropdownMenu = () => {
  $(".js-m-link").each(function initDropdownMenu() {
    const $this = $(this);
    if ($this.parent(".m-item").children(".m-dropdown").length) {
      // $this.addClass("m-dropdown-toggle");
      const dropdownMenu = $this.parent(".m-item").children(".m-dropdown");
      $this.on("click", function subpress(e) {
        if ($this.hasClass("active")) {
          $this.removeClass("active");
          dropdownMenu.removeClass("show");
        } else {
          $this.addClass("active");
          dropdownMenu.addClass("show");
        }
        e.preventDefault();
      });
    }
  });
};

export default initializeDropdownMenu;
