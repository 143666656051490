const initializeWithdrawalConsentModal = ({ modalId }) => {
  const withdrawalConsentModal = {};

  const modal = $(`#${modalId}`);

  withdrawalConsentModal.openFromButton = (buttonElement) => {
    const processButton = $(buttonElement);

    // Get confirm path from button
    const confirmPath = processButton.data("confirm-path");

    // Set confirm path in popup modal
    modal.find(".js-confirm-button").attr("href", confirmPath);

    modal.modal("show");
  };

  return withdrawalConsentModal;
};

const initializeWithdrawalConsentButtons = ({
  modalId = "withdrawalConsentModal",
  btnSelector = ".js-withdrawal-consent-btn",
}) => {
  const withdrawalConsentModal = initializeWithdrawalConsentModal({
    modalId,
  });

  $(btnSelector).each((_, buttonElement) => {
    const button = $(buttonElement);

    button.on("click", () => {
      withdrawalConsentModal.openFromButton(button);
    });
  });
};

export default initializeWithdrawalConsentButtons;
