const redeemVoucher = (voucherRedeemPath) => {
  const code = $(".js-voucher-code-input").val();
  const namePrefix = $(".js-name-prefix").val();
  if (code !== "") {
    Rails.ajax({
      url: voucherRedeemPath,
      type: "get",
      data: `code=${code}&name_prefix=${namePrefix}`,
      dataType: "script",
    });
  } else {
    $("#entercodeError").show();
    $("#voucherError").hide();
  }
};
export default redeemVoucher;
