const initializeSliders = () => {
  $(".owl-carousel").each(function myCarousel() {
    const $carousel = $(this);

    const $defaults = {
      rewind: true,
      navText: [
        "<i class='fas fa-angle-left'></i>",
        "<i class='fas fa-angle-right'></i>",
      ],
      autoHeight: true,
      autoplayTimeout: 4000,
      autoplaySpeed: 400,
      autoplayHoverPause: true,
      navSpeed: 350,
      dotsSpeed: 350,
      dragEndSpeed: 350,
    };
    const $options = {
      items: $carousel.data("owl-items"),
      margin: $carousel.data("owl-margin"),
      loop: $carousel.data("owl-loop"),
      center: $carousel.data("owl-center"),
      nav: $carousel.data("owl-nav"),
      rewind: $carousel.data("owl-rewind"),
      dots: $carousel.data("owl-dots"),
      autoplay: $carousel.data("owl-autoplay"),
    };
    const $responsive = {
      responsive: {
        0: {
          items: $carousel.data("owl-xs"),
        },
        576: {
          items: $carousel.data("owl-sm"),
        },
        768: {
          items: $carousel.data("owl-md"),
        },
        992: {
          items: $carousel.data("owl-lg"),
        },
        1200: {
          items: $carousel.data("owl-xl"),
        },
      },
    };

    $carousel.owlCarousel($.extend($defaults, $options, $responsive));
  });
};

export default initializeSliders;
