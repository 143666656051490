import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  changed(event) {
    if (event.target.value.length) {
      this.inputTargets.forEach((target) =>
        target.setAttribute("required", "")
      );
    } else if (
      this.inputTargets.find((input) => {
        return input.value.length;
      }) === undefined
    ) {
      this.inputTargets.forEach((target) => {
        target.removeAttribute("required", "");
      });
    }
  }
}
