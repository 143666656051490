const initializeFormValidations = () => {
  const forms = document.getElementsByClassName("needs-validation");
  Array.prototype.filter.call(forms, function initFormValidation(form) {
    form.addEventListener(
      "submit",
      function preventFormSubmission(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add("was-validated");
      },
      false
    );
  });
};

export default initializeFormValidations;
