const initializeLightbox = () => {
  $(".js-lightbox-media-link, .js-lightbox-media-box").each(
    function initLightbox() {
      const lightboxMedia = $(this);
      lightboxMedia.magnificPopup({
        type: "iframe",
        fixedContentPos: false,
        removalDelay: 200,
        preloader: false,
        iframe: {
          patterns: {
            youtube: {
              index: "youtube.com/",
              id: "v=",
              src: "//www.youtube.com/embed/%id%?autoplay=1&rel=0",
            },
            vimeo: {
              index: "vimeo.com/",
              id: "/",
              src: "//player.vimeo.com/video/%id%?autoplay=1",
            },
          },
          srcAction: "iframe_src",
        },
      });
    }
  );
};

export default initializeLightbox;
