const removeVoucher = (voucherRemovePath) => {
  $(".js-voucher-btn").show();
  $(".js-voucher-remove-btn").hide();
  $("#voucherError").hide();
  $(".js-voucher-code-input").val('');
  const namePrefix = $(".js-name-prefix").val();
  Rails.ajax({
    url: voucherRemovePath,
    type: "get",
    data: `name_prefix=${namePrefix}`,
    dataType: "script",
  });
};

export default removeVoucher;
