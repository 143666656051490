const initializeSideBar = () => {
  const wrapper = $(".wrapper");
  const body = $("body");
  const smToggle = $(".sm-toggle");
  const smClose = $(".sm-close");
  const smWrapper = $(".sm-wrapper");
  const smMobile = $(".sm-mobile");

  if ($(".sm-wrapper.sm-left").length) {
    body.addClass("sm-spacer-left");
  }
  if ($(".sm-wrapper.sm-right").length) {
    body.addClass("sm-spacer-right");
  }

  if ($(".sm-wrapper.dark").length) {
    smMobile.addClass("dark");
  }
  if ($(".sm-wrapper.black").length) {
    smMobile.addClass("black");
  }

  smToggle.on("click", function () {
    smWrapper.addClass("sm-show");
    wrapper.addClass("overlay");
    smMobile.addClass("overlay");
  });

  smClose.on("click", function () {
    smWrapper.removeClass("sm-show");
    wrapper.removeClass("overlay");
    smMobile.removeClass("overlay");
  });

  const stmToggle = $(".stm-toggle");
  const stmToggleWrapper = $(".stm-toggle-wrapper");
  const stmClose = $(".stm-close");
  const stmWrapper = $(".stm-wrapper");
  const stmLeft = $(".stm-left");
  const stmRight = $(".stm-right");

  if (stmLeft.length) {
    body.addClass("stm-spacer-left");
    stmToggleWrapper.addClass("stm-toggle-left");
  }
  if (stmRight.length) {
    body.addClass("stm-spacer-right");
    stmToggleWrapper.addClass("stm-toggle-right");
  }

  if ($(".stm-toggle-wrapper.dark").length) {
    stmWrapper.addClass("dark");
  }
  if ($(".stm-toggle-wrapper.black").length) {
    stmWrapper.addClass("black");
  }

  stmToggle.on("click", function () {
    if (stmWrapper.hasClass("stm-show")) {
      stmWrapper.removeClass("stm-show");
      wrapper.removeClass("overlay");
      stmToggle.removeClass("stm-toggle-active");
      stmToggleWrapper.removeClass("overlay");
    } else {
      stmWrapper.addClass("stm-show");
      wrapper.addClass("overlay");
      stmToggle.addClass("stm-toggle-active");
      stmToggleWrapper.addClass("overlay");
    }
  });

  stmClose.on("click", function () {
    stmWrapper.removeClass("stm-show");
    wrapper.removeClass("overlay");
    stmToggle.removeClass("stm-toggle-active");
    stmToggleWrapper.removeClass("overlay");
  });
};

export default initializeSideBar;
