const initializePapersViewToggle = (papersPath) => {
  $(".js-papers-view-toggle").on("change", function onViewToggle() {
    Rails.ajax({
      url: papersPath,
      type: "get",
      data: `alt_v=${$(this).is(":checked")}`,
      dataType: "script",
    });
  });
};

export default initializePapersViewToggle;
