const initializeFormWizard = () => {
  let currentFs;
  let nextFs;
  let previousFs;
  let opacity;

  $(".js-next-button").on("click", function onNextButtonClick() {
    const form = $(this).parents("form")[0];
    currentFs = $(this).parent().parent();
    nextFs = currentFs.next();

    currentFs.addClass("was-validated");

    nextFs.find("input").prop("disabled", true);

    if (form.checkValidity() === false) {
      return;
    }

    nextFs.find("input").prop("disabled", false);
    $(".js-role-radio").trigger("change");

    $(".js-form-progressbar li")
      .eq($("fieldset").index(nextFs))
      .addClass("active");

    nextFs.show();
    currentFs.animate(
      { opacity: 0 },
      {
        step(now) {
          opacity = 1 - now;

          currentFs.css({
            display: "none",
            position: "relative",
          });
          nextFs.css({ opacity });
        },
        duration: 600,
      }
    );
  });

  $(".js-previous-button").on("click", function onPreviousButtonClick() {
    currentFs = $(this).parent().parent();
    previousFs = currentFs.prev();
    nextFs = currentFs.next();

    currentFs.find("input").prop("disabled", true);
    previousFs.find("input").prop("disabled", false);

    if (nextFs) {
      nextFs.find("input").prop("disabled", true);
    }

    $(".js-form-progressbar li")
      .eq($("fieldset").index(currentFs))
      .removeClass("active");

    previousFs.show();

    currentFs.animate(
      { opacity: 0 },
      {
        step(now) {
          opacity = 1 - now;

          currentFs.css({
            display: "none",
            position: "relative",
          });
          previousFs.css({ opacity });
        },
        duration: 600,
      }
    );
  });

  $(".js-role-radio").on("change", function onRoleRadioChange() {
    if ($(this).is(":checked")) {
      $(".js-next-button").prop("disabled", false);
      if ($(this).val() === "company") {
        $(".js-company-input").show();
        $(".js-form-wizard").addClass("form-company");
        $(".js-company-input input").attr("required", "required");
      } else {
        $(".js-company-input").hide();
        $(".js-form-wizard").removeClass("form-company");
        $(".js-company-input input").removeAttr("required");
      }
    }
  });
  $(".js-role-radio").trigger("change");

  $(".js-company-taxable").on("change", function onRoleRadioChange() {
    if ($(this).is(":checked")) {
      $(".js-next-button").prop("disabled", false);
      if ($(this).val() === "true") {
        $(".js-company-taxable-input").show();
        $(".js-form-wizard").addClass("form-company");
        $(".js-company-taxable-input input").attr("required", "required");
      } else {
        $(".js-company-taxable-input").hide();
        $(".js-form-wizard").removeClass("form-company");
        $(".js-company-taxable-input input").removeAttr("required");
      }
    }
  });
  $(".js-company-taxable").trigger("change");

  $(".js-vehicle-owner-radio").on("change", function onRoleRadioChange() {
    if ($(this).is(":checked")) {
      if ($(this).val() === "false") {
        $(".js-vehicle-owner-input").show();
        $(".js-vehicle-owner-input .js-required-input").attr("required", "required");
      } else {
        // $(".js-vehicle-owner-input").hide();
        // $(".js-vehicle-owner-input .js-required-input").removeAttr("required");
        $(".js-vehicle-owner-input").show();
        $(".js-vehicle-owner-input .js-required-input").attr("required", "required");
      }
    }
  });
  $(".js-vehicle-owner-radio").trigger("change");

  $(".js-company-taxable-radio").on("change", function onRoleRadioChange() {
    if ($(this).is(":checked")) {
      if ($(this).val() === "true") {
        $(".js-company-taxable-input").show();
        $(".js-company-taxable-input .js-required-input").attr("required", "required");
      } else {
        $(".js-company-taxable-input").hide();
        $(".js-company-taxable-input .js-required-input").removeAttr("required");
      }
    }
  });
  $(".js-company-taxable-radio").trigger("change");
};

export default initializeFormWizard;
