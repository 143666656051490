const initializeEmailValidator = (emailvalidatorsPath) => {
  const form = document.querySelector(".js-registration-form");
  $(".js-validate-email").on("click", function emailvalidate() {
    if (form.checkValidity() === true) {
      this.disabled = true;
      $(".js-submit-loading-indicator").show();
      Rails.ajax({
        url: emailvalidatorsPath,
        type: "post",
        data: `email=${$(".js-email-field").val()}`,
        dataType: "script",
      });
    }
    form.classList.add("was-validated");
  });
};

export default initializeEmailValidator;
