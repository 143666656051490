import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["from", "to", "toYearConstraint"];

  changed() {
    this.fromTarget.setAttribute("max", this.toTarget.value);
    this.toTarget.setAttribute("min", this.fromTarget.value);

    if (this.toYearConstraintTarget) {
      this.applyYearConstraints();
    }
  }

  // Private
  applyYearConstraints() {
    const year = new Date(this.fromTarget.value).getFullYear();
    const currentDate = new Date();
    if (year === currentDate.getFullYear()) {
      this.toYearConstraintTarget.setAttribute("max", `${year}-${currentDate.getMonth() + 1}-${currentDate.getDate()
        }`);
    } else {
      this.toYearConstraintTarget.setAttribute("max", `${year}-12-31`);
    }
  }
}
