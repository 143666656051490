const initializeSmoothScroll = () => {
  $('a[href*="#"]')
    .not('[href="#"]')
    .not('[data-toggle="tab"]')
    .on("click", function onLinkClick(e) {
      if (
        window.location.pathname.replace(/^\//, "") ===
          this.pathname.replace(/^\//, "") &&
        window.location.hostname === this.hostname
      ) {
        let target = $(this.hash);
        target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
        if (target.length) {
          $("html,body").animate(
            { scrollTop: target.offset().top },
            450,
            "easeInOutQuart"
          );
          e.preventDefault();
        }
      }
    });
};

export default initializeSmoothScroll;
