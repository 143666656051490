const initializeShowPassword = () => {
  $(".js-toggle-password").on("click", function showPassword() {
    $(this).toggleClass("fa-eye fa-eye-slash");
    if ($(".password").attr("type") === "password") {
      $(".password").attr("type", "text");
    } else {
      $(".password").attr("type", "password");
    }
  });
};
export default initializeShowPassword;
