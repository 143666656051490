const initializeSubmitOnEnter = () => {
  const form = document.querySelector(".js-registration-form");
  const clickSubmit = document.querySelector(".js-validate-email");
  if (form) {
    form.addEventListener("keydown", function onEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        clickSubmit.click();
        return false;
      }
    });
  }
};
export default initializeSubmitOnEnter;
