import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "input", "submitButton", "cancelButton"];

  connect() {
    if (this.inputTarget) {
      // this.inputTarget.focus();
    }
  }

  submitChanges() {
    if (this.containerTarget.checkValidity() === true) {
      // let data = this.inputTargets.map((target) => {
      //   return `${target.name}=${target.value}`;
      // });

      const data = {
        attribute: this.inputName,
      };
      this.inputTargets.forEach((target) => {
        data[target.name] = target.value;
      });

      // data.push(`attribute=${this.inputName}`);
      // data = data.join("&");

      Rails.ajax({
        url: this.url,
        type: "put",
        data: new URLSearchParams(data).toString(),
        dataType: "json",
        success: (result) => {
          this.dispatch("editSuccessful", { detail: { sourceIdentifier: this.sourceIdentifier, result: result }, target: window });
        },
        error: function (result) { }
      });
    }
    this.containerTarget.classList.add("was-validated");
  }

  cancelEditMode() {
    this.dispatch("cancelEditMode", { target: window });
  }

  get url() {
    return this.data.get("url");
  }

  get inputName() {
    return this.data.get("inputName");
  }

  get sourceIdentifier() {
    return this.data.get("sourceIdentifier");
  }
}
