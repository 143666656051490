const initializePaperProcessModal = ({ modalId }) => {
  const paperProcessModal = {};

  const modal = $(`#${modalId}`);
  const modalDialog = modal.find(".modal-dialog");

  paperProcessModal.openFromButton = (buttonElement) => {
    const processButton = $(buttonElement);

    // Get paper status from button
    const status = processButton.data("status");

    // Set status in popup modal
    modalDialog.removeClass(function removePreviousClass(index, className) {
      return (className.match(/(^|\s)status-\S+/g) || []).join(" ");
    });
    modalDialog.addClass(`status-${status}`);

    modal.modal("show");
  };

  return paperProcessModal;
};

const initializePaperProcessButtons = ({
  modalId = "paperProcessModal",
  btnSelector = ".js-paper-process-btn",
}) => {
  const paperProcessModal = initializePaperProcessModal({
    modalId,
  });

  $(btnSelector).each((_, buttonElement) => {
    const button = $(buttonElement);

    button.on("click", () => {
      paperProcessModal.openFromButton(button);
    });
  });
};

export default initializePaperProcessButtons;
