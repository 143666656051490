import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["load"];

  connect() {
    if (this.loadTarget.dataset.select2Id === undefined) {
      $(this.loadTarget).select2();
    }
  }
}
