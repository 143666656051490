const initializeSelect = () => {
  $(".select2").select2();

  $(".js-paranted-select2").select2({
    language: "de",
    dropdownParent: $($(this).data("dropdown-parent")),
  });

  $(".js-ajax-select2").select2({
    language: "de",
    dropdownParent: $($(this).data("dropdown-parent")),
    ajax: {
      quietMillis: 300,
      data: function (params) {
        var query = {
          q: params.term,
          display_scope: $(this).data("display-scope"),
          select_keys: $(this).data("select-keys")
        }
        return query;
      }
    }
  });

  $(document).on("select2:open", () => {
    document
      .querySelector(".select2-container--open .select2-search__field")
      .focus();
  });
};

export default initializeSelect;
