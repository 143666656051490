import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loadingIndicatorModal"];

  toggleIndicator(event) {
    const form = event.target;
    if (form.checkValidity() === true) {
      const modal = bootstrap.Modal.getOrCreateInstance(
        this.loadingIndicatorModalTarget
      );
      modal.show();
    }
  }
}
