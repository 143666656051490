const initializeScrollToTop = () => {
  const scrollTopBtn = $(".scrolltotop");

  if (scrollTopBtn.length) {
    $(window).on("scroll", function onScroll() {
      if ($(this).scrollTop() > 700) {
        scrollTopBtn.addClass("scrolltotop-show");
      } else {
        scrollTopBtn.removeClass("scrolltotop-show");
      }
    });

    scrollTopBtn.on("click", function onClick(e) {
      $("html,body").animate({ scrollTop: 0 }, 450, "easeInOutQuart");
      e.preventDefault();
    });
  }
};

export default initializeScrollToTop;
