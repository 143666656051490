import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  sendRequest() {
    const data = {};
    this.inputTargets.forEach((target) => {
      if (target.type === "checkbox") {
        data[target.name] = target.checked;
      } else {
        data[target.name] = target.value;
      }
    });
    Rails.ajax({
      url: this.url,
      type: "put",
      data: new URLSearchParams(data).toString(),
      dataType: "script",
    });
  }

  get url() {
    return this.data.get("url");
  }
}
