import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "input", "popover", "template"];

  connect() {
    if (!this.element.id || this.element.id == "") {
      this.element.id =
        this.inputName +
        new Date().getTime() +
        Math.random().toString(36).substring(2);
    }
  }

  triggerEditMode() {
    if (this.editMode !== "true") {
      this.dispatch("cancelEditMode", {
        detail: { sourceIdentifier: this.element.id },
        target: window,
      });
      this.containerTarget.setAttribute("data-edit-mode", "true");

      this.popover = this.buildPopover;
      this.popover.show();
    }
  }

  editSuccessful({ detail: { sourceIdentifier, result } }) {
    if (sourceIdentifier === this.element.id) {
      this.data.set("value", result.value);
      this.inputTargets.forEach((target) => {
        target.value = result[target.name];
      });
      this.containerTarget.innerHTML = result.display_value;
      this.containerTarget.removeAttribute("data-edit-mode");
      if (this.popover) {
        this.popover.dispose();
        this.popover = null;
      }
    }
  }

  cancelEditMode({ detail: { sourceIdentifier } }) {
    if (sourceIdentifier !== this.element.id) {
      this.containerTarget.removeAttribute("data-edit-mode");
      if (this.popover) {
        this.popover.dispose();
        this.popover = null;
      }
    }
  }

  // Private
  get buildPopover() {
    return bootstrap.Popover.getOrCreateInstance(this.containerTarget, {
      container: "body",
      trigger: "manual",
      html: true,
      sanitize: false,
      content: this.formContent,
      customClass: this.popoverClass,
    });
  }

  get formContent() {
    if (this.hasTemplateTarget) {
      return this.templateTarget.innerHTML;
    } else {
      return `
      <form data-controller="inlineEditorForm" data-inlineEditorForm-target="container" data-inlineEditorForm-source-identifier="${this.element.id}" data-inlineEditorForm-url="${this.url}" data-inlineEditorForm-input-name="${this.inputName}" onSubmit="return false;">
      ${this.inputField}
      ${this.submitButton}
      ${this.cancelButton}
      </form>`;
    }
  }

  get inputField() {
    return `<input
    data-inlineEditorForm-target="input"
    class="${this.inputClass}"
    name="${this.inputName}"
    style="${this.inputStyle}"
    type="${this.inputType}"
    placeholder="${this.inputPlaceholder}"
    value="${this.value}"
    ${this.inputAttributedRaw}>`;
  }

  get submitButton() {
    return `<button
    type="button"
    data-inlineEditorForm-target="submitButton"
    data-action="click->inlineEditorForm#submitChanges"
    class="${this.buttonClass}"><i class="fa fa-check"></i></button>`;
  }

  get cancelButton() {
    return `<button
    type="button"
    data-inlineEditorForm-target="cancelButton"
    data-action="click->inlineEditorForm#cancelEditMode"
    class="${this.buttonClass}"><i class="fa fa-times"></i></button>`;
  }

  get url() {
    return this.data.get("url");
  }

  get inputClass() {
    return this.data.get("inputClass") || "form-control required";
  }

  get buttonClass() {
    return this.data.get("buttonClass") || "px-5 bg-success text-light";
  }

  get inputName() {
    return this.data.get("inputName");
  }

  get inputStyle() {
    return this.data.get("inputStyle");
  }

  get inputType() {
    return this.data.get("inputType") || "text";
  }

  get inputPlaceholder() {
    return this.data.get("inputPlaceholder");
  }

  get inputAttributedRaw() {
    return this.data.get("inputAttributesRaw");
  }

  get value() {
    return this.data.get("value");
  }

  get editMode() {
    return this.containerTarget.getAttribute("data-edit-mode");
  }

  get popoverClass() {
    return this.data.get("popoverClass") || "";
  }
}
