const initializeAccordion = () => {
  $(".accordion-title").each(function forAccordionTitle() {
    const $this = $(this);

    $this.on("click", function onAccordionClick() {
      const accordionList = $this.parent("li");
      const accordionContent = this.nextElementSibling;

      if (accordionList.hasClass("active")) {
        accordionList.removeClass("active");
        accordionContent.style.maxHeight = null;
      } else {
        accordionList.addClass("active");
        if ($this.closest(".accordion").hasClass("single-open")) {
          $this.closest(".accordion").children("li").removeClass("active");
          accordionList.addClass("active");
          $this
            .parents(".single-open")
            .find(".accordion-content")
            .css("max-height", "0");
        }
        accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
      }
    });

    //
    // Give max-height to Accordion's active content //
    //
    if ($this.parents(".accordion").find("li").hasClass("active")) {
      const accordionActiveContent = $this
        .parents(".accordion")
        .find("li.active")
        .children(".accordion-content");
      const accordionHeight = accordionActiveContent.prop("scrollHeight");

      accordionActiveContent.css({ "max-height": `${accordionHeight}px` });
    }
  });
};

export default initializeAccordion;
